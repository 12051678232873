import React, { useState, useEffect } from 'react'
import styles from './reportLeak.module.css'
import logo from '../../assets/images/logo.png'
import Button from '@material-ui/core/Button'
import {
  FormGroup,
  FormControl,
  InputLabel,
  Input,
  CircularProgress,
  MenuItem,
  Divider
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import appPaths from '../../applicationPaths.json'
import Zoom from '@material-ui/core/Zoom'
import { useAuth0 } from '../../react-auth0-wrapper'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Redirect } from 'react-router'
import PlacesAutosuggest from './PlacesAutosuggest'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  report_secondaryButton: {
    backgroundColor: '#535b69',
    color: '#fff',
    width: '100%',
    marginTop: '12px',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: '#494f5a'
    }
  },

  report_flexDirection: {
    flexDirection: 'row'
  }
}))

export default function ReportLeak (props) {
  const classes = useStyles()
  const [description, setDescription] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [contactPersonFirstName, setContactPersonFirstName] = useState('')
  const [contactPersonLastName, setContactPersonLastName] = useState('')
  const [contactPersonPhone, setContactPersonPhone] = useState('')
  const [customer, setCustomer] = useState('')
  const [contactType, setContactType] = useState('')
  const [customers, setCustomers] = useState([])
  const { getTokenSilently, user } = useAuth0()
  const [newCustomerChecked, setNewCustomerChecked] = useState(false)
  const [newJobsiteChecked, setNewJobsiteChecked] = useState(false)
  const [jobsiteStreetAddress, setJobsiteStreetAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [building, setBuilding] = useState('')
  const [lockbox, setLockbox] = useState('')
  const [salesperson, setSalesperson] = useState('')
  const [salespeople, setSalespeople] = useState([])
  const [ladderNeeded, setLadderNeeded] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(
    'Alert Successfully Created!'
  )
  const [timetrackerSnackbarOpen, setTimetrackerSnackbarOpen] = useState(false)
  const [toAlertDetails, setToAlertDetails] = useState({
    set: false,
    location: '/'
  })
  const [loading, setLoading] = useState(false)
  const [customerJobsites, setCustomerJobsites] = useState([])
  const [selectedJobsites, setSelectedJobsites] = useState([])
  const [customerJobsite, setCustomerJobsite] = useState('')
  const [jobType, setJobType] = useState('')
  const [jobName, setJobName] = useState('')
  const [newContactChecked, setNewContactChecked] = useState(false)
  const [contact, setContact] = useState([])
  const [existingContact, setExistingContact] = useState('')

  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently()

        const response = await fetch(appPaths.apiPath + '/api/getCustomers', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          }
        })

        const responseData = await response.json()

        if (responseData.message) {
          setCustomers(responseData.data.response.data)
        } else {
        }
      } catch (error) {
        console.error(error)
      }
    }
    callApi()
  }, [getTokenSilently])

  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently()

        const response = await fetch(appPaths.apiPath + '/api/getSalespeople', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          }
        })

        const responseData = await response.json()
        if (responseData.message) {
          setSalespeople(responseData.data.response.data)
        } else {
        }
      } catch (error) {
        console.error(error)
      }
    }
    callApi()
  }, [getTokenSilently])

  useEffect(() => {
    if (customerJobsites.length === 0) {
      const callApi = async () => {
        try {
          const token = await getTokenSilently()

          const response = await fetch(
            appPaths.apiPath + '/api/getJobsiteDetails',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              //body: JSON.stringify({id: customer.fieldData.__ID})
              body: JSON.stringify({ id: '*' })
            }
          )

          const responseData = await response.json()

          if (responseData.message) {
            let jobSites = responseData.data.response.data

            let uniqueJobSites = []
            // Only take distinct jobsites based on address
            jobSites.forEach(jobSite => {
              var i = uniqueJobSites.findIndex(
                item =>
                  item.fieldData.address_Street1_Ae ==
                    jobSite.fieldData.address_Street1_Ae &&
                  item.fieldData.address_City_Ae ==
                    jobSite.fieldData.address_City_Ae
              )

              if (i <= -1) {
                uniqueJobSites.push(jobSite)
                console.log(uniqueJobSites, 'unique job sites')
              }
            })
            setCustomerJobsites(jobSites)
          } else {
          }
        } catch (error) {
          console.error(error)
        }
      }
      callApi()
    }
  }, [getTokenSilently])

  const formValid = async callback => {
    if (description == '') {
      return false
    } else if (!newCustomerChecked && customer == '') {
      return false
    } else if (
      newCustomerChecked &&
      (companyName == '' || (companyPhone == '' && companyEmail == ''))
    ) {
      return false
    } else if (
      jobsiteStreetAddress == '' ||
      city == '' ||
      zip == '' ||
      state == ''
    ) {
      return false
    } else if (newJobsiteChecked && !newCustomerChecked && building == '') {
      return false
    } else if (
      !newJobsiteChecked &&
      !newCustomerChecked &&
      customerJobsite == ''
    ) {
      return false
    } else {
      return true
    }
  }

  const createAlert = async () => {
    const result = await formValid()
    if (result) {
      console.log(result, 'result')
      setLoading(true)

      try {
        const token = await getTokenSilently()
        const response = await fetch(
          appPaths.apiPath + '/api/createAlertStaff',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // sent request
              Accept: 'application/json' // expected data sent back
            },
            body: JSON.stringify({
              description: description,
              newCustomer: newCustomerChecked,
              customer: customer,
              companyName: companyName,
              companyEmail: companyEmail,
              companyPhone: companyPhone,
              jobsiteStreetAddress: jobsiteStreetAddress,
              city: city,
              state: state,
              zip: zip,
              contactPersonFirstName: contactPersonFirstName,
              contactPersonLastName: contactPersonLastName,
              contactPersonPhone: contactPersonPhone,
              existingContact: existingContact,
              contactMethod: setContactType,
              contactType: contactType,
              jobBuilding: building,
              jobLockbox: lockbox,
              ladderNeeded: ladderNeeded,
              salesperson: salesperson,
              jobType: jobType,
              createdBy: user.name
            })
          }
        )

        const responseData = await response.json()
        setLoading(false)
        if (responseData.message) {
          setSnackbarMessage('Alert Succesfully Created!')
          setTimetrackerSnackbarOpen(true)
          alert('Alert Succesfully Created!')
          let data = { set: true, to: { pathname: '/', state: {} } }
          setToAlertDetails(data)
        } else {
          setSnackbarMessage('Alert Creation Failed!')
          setTimetrackerSnackbarOpen(true)
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      alert('One or more required fields is blank!')
    }
  }

  useEffect(() => {
    if (newJobsiteChecked) {
      setCustomerJobsite('')
      setBuilding('')
      setJobsiteStreetAddress('')
      setCity('')
      setState('')
      setZip('')
    }
  }, [newJobsiteChecked])

  const options = [
    <MenuItem key='0' value='Company Email'>
      Company Email
    </MenuItem>,
    <MenuItem key='1' value='Company Phone'>
      Company Phone
    </MenuItem>,
    <MenuItem key='2' value='Contact Phone'>
      Contact Phone
    </MenuItem>
  ]

  const jobTypeOptions = [
    <MenuItem key='0' value='Curbs/Service'>
      Curbs/Service
    </MenuItem>,
    <MenuItem key='1' value='New Construction'>
      New Construction
    </MenuItem>,
    <MenuItem key='2' value='Repair'>
      Repair
    </MenuItem>,
    <MenuItem key='3' value='Reroof'>
      Reroof
    </MenuItem>,
    <MenuItem key='4' value='Roof Leak'>
      Roof Leak
    </MenuItem>,
    <MenuItem key='5' value='Roof Maintenance'>
      Roof Maintenance
    </MenuItem>,
    <MenuItem key='6' value='Sheet Metal'>
      Sheet Metal
    </MenuItem>,
    <MenuItem key='7' value='No Charge Warranty'>
      No Charge Warranty
    </MenuItem>
  ]

  const handleTimetrackerSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setTimetrackerSnackbarOpen(false)
  }
  return (
    <div className={styles.reportleak_wrapper}>
      {toAlertDetails.set && <Redirect push to={toAlertDetails.to} />}

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={timetrackerSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleTimetrackerSnackbarClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{snackbarMessage}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={handleTimetrackerSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />

      <Zoom in={true} {...{ timeout: 500 }}>
        <Paper className={styles.report_innerWrapper}>
          {!loading && (
            <>
              <img src={logo} className={styles.report_logo} alt='logo' />

              <div>
                <h2 className={styles.report_heading}>Staff Fix-It! Request</h2>
                <p className={styles.report_required}>* = required</p>
                <p>(Please allow the page to sit for a few seconds in order for the list of jobs/addresses to fully populate in the form.)</p>
              </div>
              {/* Check to see if any items are found*/}

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={styles.report_input}
                      checked={newCustomerChecked}
                      onChange={() => {
                        setNewCustomerChecked(!newCustomerChecked)
                      }}
                      value='newCustomerChecked'
                      inputProps={{
                        'aria-label': 'Add New Customer'
                      }}
                    />
                  }
                  label='Add New Customer'
                />
              </FormControl>

              {newCustomerChecked && (
                <div className={styles.report_sectionBackground}>
                  <FormControl className={styles.report_fullWidth}>
                    <InputLabel>
                      Company Name{' '}
                      <span className={styles.report_required}>*</span>
                    </InputLabel>
                    <Input
                      required
                      type='text'
                      className={styles.report_input}
                      placeholder='Enter Company Name'
                      name='companyName'
                      value={companyName}
                      onChange={event => {
                        setCompanyName(event.target.value)
                      }}
                    />
                  </FormControl>

                  <FormControl className={styles.report_fullWidth}>
                    <InputLabel>
                      Company Email{' '}
                      <span className={styles.report_required}>*</span>
                    </InputLabel>
                    <Input
                      required
                      type='email'
                      className={styles.report_input}
                      placeholder='Enter email'
                      name='companyEmail'
                      value={companyEmail}
                      onChange={event => {
                        setCompanyEmail(event.target.value)
                      }}
                    />
                  </FormControl>

                  <FormControl className={styles.report_fullWidth}>
                    <InputLabel>
                      Company Phone{' '}
                      <span className={styles.report_required}>*</span>
                    </InputLabel>
                    <Input
                      required
                      type='tel'
                      className={styles.report_input}
                      placeholder='Enter Phone'
                      name='companyPhone'
                      value={companyPhone}
                      onChange={event => {
                        setCompanyPhone(event.target.value)
                      }}
                    />
                  </FormControl>
                </div>
              )}

              {!newCustomerChecked && (
                <>
                  <FormControl>
                    <InputLabel>
                      Choose Existing Customer{' '}
                      <span className={styles.report_required}>*</span>
                    </InputLabel>
                    <Select
                      className={styles.report_input}
                      value={customer}
                      onChange={event => {
                        /* When a customer is selected */
                        //if jobsite is empty
                        setCustomer(event.target.value)

                        let currentJobsites = customerJobsites.filter(
                          jobsite =>
                            jobsite.fieldData._idf_customer ===
                            event.target.value.fieldData.__ID
                        )
                        setContact(currentJobsites)
                        console.log(currentJobsites, 'current job sites!!!!')

                        if (currentJobsites.length === 0) {
                          setNewJobsiteChecked(true)
                          setSelectedJobsites([])
                          setCustomerJobsite('')
                          setBuilding('')
                          setJobsiteStreetAddress('')
                          setCity('')
                          setState('')
                          setZip('')
                        } else if (currentJobsites.length === 1) {
                          setNewJobsiteChecked(false)
                          setSelectedJobsites(currentJobsites)
                          setCustomerJobsite(
                            currentJobsites[0].fieldData.Job_Name
                          )
                          setBuilding(currentJobsites[0].fieldData.Job_Name)
                          setJobsiteStreetAddress(
                            currentJobsites[0].fieldData.address_Street1_Ae
                          )
                          setCity(currentJobsites[0].fieldData.address_City_Ae)
                          setState(
                            currentJobsites[0].fieldData.address_State_Ae
                          )
                          setZip(currentJobsites[0].fieldData.address_Zip_Ae)
                        } else {
                          setNewJobsiteChecked(false)
                          setSelectedJobsites(currentJobsites)
                          setCustomerJobsite('')
                          setBuilding('')
                          setJobsiteStreetAddress('')
                          setCity('')
                          setState('')
                          setZip('')
                        }
                      }}
                      input={<Input id='customer' name='customer' />}
                    >
                      {customers.map(cust => (
                        <MenuItem value={cust} key={cust.fieldData.__ID}>
                          {cust.fieldData.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {customer != '' && !newCustomerChecked && (
                <>
                  <FormControl>
                    <FormControlLabel
                      className={styles.report_input}
                      control={
                        <Checkbox
                          // defaultChecked={false}
                          defaultChecked={newJobsiteChecked}
                          onChange={() => {
                            setNewJobsiteChecked(!newJobsiteChecked)
                          }}
                          checked={newJobsiteChecked}
                          value='newJobsiteChecked'
                         inputProps={{
                            'aria-label': 'Add New Jobsite'
                          }}
                        />
                      }
                      label={'Add New Jobsite for ' + customer.fieldData.Name}
                    />
                  </FormControl>
                </>
              )}

              {newJobsiteChecked && false && (
                <FormControl>
                  <InputLabel>
                    Name of Job/Building/Tenant:{' '}
                    <span className={styles.report_required}>*</span>
                  </InputLabel>
                  <Input
                    type='text'
                    className={styles.report_input}
                    placeholder='Building/Tenant'
                    name='building'
                    value={building}
                    onChange={event => {
                      setBuilding(event.target.value)
                    }}
                  />
                </FormControl>
              )}

              {newCustomerChecked && false && (
                <FormControl>
                  <InputLabel>Name of Job/Building/Tenant: </InputLabel>
                  <Input
                    type='text'
                    className={styles.report_input}
                    placeholder='Building/Tenant'
                    name='building'
                    value={building}
                    onChange={event => {
                      setBuilding(event.target.value)
                    }}
                  />
                </FormControl>
              )}

              {!newCustomerChecked && !newJobsiteChecked && customer != '' && (
                <FormControl>
                  <InputLabel>
                    Choose existing Job/Building/Tenant (if list is blank, click 'Add New Jobsite' above){' '}
                    <span className={styles.report_required}>*</span>
                  </InputLabel>
                  <Select
                    className={styles.report_input}
                    value={customerJobsite}
                    onChange={event => {
                      let currentJobsite = null
                      if (
                        selectedJobsites.filter(
                          jobsite =>
                            jobsite.fieldData.Job_Name == event.target.value
                        ).length > 0
                      ) {
                        currentJobsite = selectedJobsites.filter(
                          jobsite =>
                            jobsite.fieldData.Job_Name == event.target.value
                        )[
                          selectedJobsites.filter(
                            jobsite =>
                              jobsite.fieldData.Job_Name == event.target.value
                          ).length - 1
                        ]
                      }
                      if (currentJobsite != null) {
                        setNewJobsiteChecked(false)
                        setBuilding(currentJobsite.fieldData.Job_Name)
                        setJobsiteStreetAddress(
                          currentJobsite.fieldData.address_Street1_Ae
                        )
                        setCity(currentJobsite.fieldData.address_City_Ae)
                        setState(currentJobsite.fieldData.address_State_Ae)
                        setZip(currentJobsite.fieldData.address_Zip_Ae)
                      }
                      setCustomerJobsite(event.target.value)
                    }}
                    input={<Input id='jobsite' name='jobsite' />}
                  >
                    {[
                      ...new Set(
                        selectedJobsites.map(material => {
                          return material.fieldData.Job_Name
                        })
                      )
                    ].map(cust => (
                      <MenuItem value={cust} key={cust}>
                        {cust}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormGroup className={classes.report_flexDirection}>
                <FormControl className={styles.report_fullWidth}>
                  <InputLabel>
                    Name of Job/Building/Tenant:{' '}
                    <span className={styles.report_required}>*</span>
                  </InputLabel>
                  <Input
                    type='text'
                    className={styles.report_input}
                    placeholder='Building/Tenant'
                    name='building'
                    value={building}
                    onChange={event => {
                      setBuilding(event.target.value)
                    }}
                  />
                </FormControl>
                <FormControl className={styles.report_fullWidth}>
                  {!(newCustomerChecked || newJobsiteChecked) && (
                    <>
                      <InputLabel>
                        Jobsite Street Address:{' '}
                        <span className={styles.report_required}>*</span>
                      </InputLabel>
                      <Input
                        className={styles.report_input}
                        type='text'
                        placeholder='Jobsite Street Address'
                        name='jobsiteStreetAddress'
                        value={jobsiteStreetAddress}
                        onChange={event => {
                          setJobsiteStreetAddress(event.target.value)
                        }}
                      />
                    </>
                  )}

                  {(newCustomerChecked || newJobsiteChecked) && (
                    <PlacesAutosuggest
                      className={styles.report_input}
                      changeValue={value => {
                        setJobsiteStreetAddress(value)
                      }}
                      value={jobsiteStreetAddress}
                      changeChoice={address => {
                        setJobsiteStreetAddress(
                          address.home + ' ' + address.street
                        )
                        setCity(address.city)
                        setState(address.region)
                        setZip(address.postal_code)
                      }}
                      label='Jobsite Street Address'
                      placeholder='Jobsite Street Address'
                    />
                  )}
                </FormControl>
                <FormControl className={styles.report_halfWidth}>
                  <InputLabel>
                    City: <span className={styles.report_required}>*</span>
                  </InputLabel>
                  <Input
                    className={styles.report_spacing}
                    type='text'
                    placeholder='City'
                    name='city'
                    value={city}
                    onChange={event => {
                      setCity(event.target.value)
                    }}
                  />
                </FormControl>
                <FormControl className={styles.report_halfWidth}>
                  <InputLabel>
                    State: <span className={styles.report_required}>*</span>
                  </InputLabel>
                  <Input
                    className={styles.report_spacing}
                    type='text'
                    placeholder='State'
                    name='state'
                    value={state}
                    onChange={event => {
                      setState(event.target.value)
                    }}
                  />
                </FormControl>
                <FormControl className={styles.report_halfWidth}>
                  <InputLabel>
                    Zip: <span className={styles.report_required}>*</span>
                  </InputLabel>
                  <Input
                    className={styles.report_bottomSpacing}
                    type='text'
                    placeholder='Zip'
                    name='zip'
                    value={zip}
                    onChange={event => {
                      setZip(event.target.value)
                    }}
                  />
                </FormControl>
              </FormGroup>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newContactChecked}
                      onChange={() => {
                        setNewContactChecked(!newContactChecked)
                      }}
                      value='newContactChecked'
                      inputProps={{
                        'aria-label': 'Add New Contact'
                      }}
                    />
                  }
                  label={
                    customer
                      ? 'Add New Contact for ' + customer.fieldData.Name
                      : 'Add Contact'
                  }
                />
              </FormControl>
              {!newContactChecked && (<FormControl>
                <InputLabel>
                  Choose Existing Contact{' '}
                  {/* <span className={styles.report_required}>*</span> */}
                </InputLabel>
                <Select
                  className={styles.report_input}
                  value={existingContact}
                  onChange={event => {
                    /* When a customer is selected */
                    //if jobsite is empty
                    setExistingContact(event.target.value)

                    // let currentJobsites = customerJobsites.filter(
                    //   jobsite =>
                    //     jobsite.fieldData._idf_customer ===
                    //     event.target.value.fieldData.__ID
                    // )
                    // if (currentJobsites.length === 0) {
                    //   setNewJobsiteChecked(true)
                    //   setCustomerJobsite('')
                    //   setBuilding('')
                    //   setJobsiteStreetAddress('')
                    //   setCity('')
                    //   setState('')
                    //   setZip('')
                    // } else if (currentJobsites.length === 1) {
                    //   setNewJobsiteChecked(false)
                    //   setSelectedJobsites(currentJobsites)
                    //   setCustomerJobsite(currentJobsites[0].fieldData.Job_Name)
                    //   setBuilding(currentJobsites[0].fieldData.Job_Name)
                    //   setJobsiteStreetAddress(
                    //     currentJobsites[0].fieldData.address_Street1_Ae
                    //   )
                    //   setCity(currentJobsites[0].fieldData.address_City_Ae)
                    //   setState(currentJobsites[0].fieldData.address_State_Ae)
                    //   setZip(currentJobsites[0].fieldData.address_Zip_Ae)
                    // } else {
                    //   setNewJobsiteChecked(false)
                    //   setSelectedJobsites(currentJobsites)
                    //   setCustomerJobsite('')
                    //   setBuilding('')
                    //   setJobsiteStreetAddress('')
                    //   setCity('')
                    //   setState('')
                    //   setZip('')
                    // }
                  }}
                  input={<Input id='contact' name='contact' />}
                >
                  {[
                    ...new Set(
                      contact.map(con => {
                        return con.fieldData.JobSite_ContactName
                      })
                    )
                  ].map(c =>
                    c !== '' ? (
                      <MenuItem value={c} key={c}>
                        {c}
                      </MenuItem>
                    ) : (
                      ''
                    )
                  )}
                </Select>
              </FormControl> )}
              {newContactChecked && (
                <>
                  <FormControl>
                    <InputLabel>Contact Person First Name:</InputLabel>
                    <Input
                      required
                      className={styles.report_input}
                      type='text'
                      placeholder='Enter First Name'
                      name='contactFirstName'
                      value={contactPersonFirstName}
                      onChange={event => {
                        setContactPersonFirstName(event.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>Contact Person Last Name:</InputLabel>
                    <Input
                      className={styles.report_input}
                      required
                      type='text'
                      placeholder='Enter Last Name'
                      name='contactLastName'
                      value={contactPersonLastName}
                      onChange={event => {
                        setContactPersonLastName(event.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>Contact Person Phone:</InputLabel>
                    <Input
                      className={styles.report_input}
                      type='text'
                      placeholder='EnterPhone'
                      name='contactPhone'
                      value={contactPersonPhone}
                      onChange={event => {
                        setContactPersonPhone(event.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>Contact Method:</InputLabel>
                    <Select
                      className={styles.report_input}
                      value={contactType}
                      onChange={event => {
                        setContactType(event.target.value)
                      }}
                      input={<Input id='contactType' name='contactType' />}
                    >
                      {options}
                    </Select>
                  </FormControl>
                </>
              )}

              <FormControl>
                <InputLabel>
                  Description of Problem{' '}
                  <span className={styles.report_required}>*</span>
                </InputLabel>
                <Input
                  className={styles.report_input}
                  type='text'
                  placeholder='Enter Description of the Problem'
                  name='description'
                  required
                  value={description}
                  onChange={event => {
                    setDescription(event.target.value)
                  }}
                />
              </FormControl>

              <FormControl>
                <InputLabel>Job Type:</InputLabel>
                <Select
                  className={styles.report_input}
                  value={jobType}
                  onChange={event => {
                    setJobType(event.target.value)
                  }}
                  input={<Input id='jobType' name='jobType' />}
                >
                  {jobTypeOptions}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Salesperson</InputLabel>
                <Select
                  className={styles.report_input}
                  value={salesperson}
                  onChange={event => {
                    setSalesperson(event.target.value)
                  }}
                  input={<Input id='customer' name='customer' />}
                >
                  {salespeople.map(cust => (
                    <MenuItem value={cust} key={cust.fieldData.__ID}>
                      {cust.fieldData.Name_combined_ae}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>
                  Roof Access Info/Lockbox Code/Tech Notes:
                </InputLabel>
                <Input
                  className={styles.report_input}
                  type='text'
                  placeholder='Roof Access Info/Lockbox Code'
                  name='lockboxCode'
                  value={lockbox}
                  onChange={event => {
                    setLockbox(event.target.value)
                  }}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  className={styles.report_input}
                  control={
                    <Checkbox
                      checked={ladderNeeded}
                      onChange={() => {
                        setLadderNeeded(!ladderNeeded)
                      }}
                      value='ladder'
                      inputProps={{
                        'aria-label': 'primary checkbox'
                      }}
                    />
                  }
                  label='Ladder Needed?'
                />
              </FormControl>

              <Button
                variant='contained'
                className={classes.report_secondaryButton}
                onClick={() => {
                  createAlert()
                }}
              >
                Submit
              </Button>
            </>
          )}

          {loading && <CircularProgress></CircularProgress>}
        </Paper>
      </Zoom>
    </div>
  )
}
