import React, { useState, useEffect, useContext } from 'react'
import { useAuth0 } from '../../react-auth0-wrapper'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import 'react-html5-camera-photo/build/css/index.css'
import appPaths from '../../applicationPaths.json'
import JobDetails from './JobDetails.js'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import JobTechs from './JobTechs'
import { Redirect } from 'react-router'
import Timecard from './Timecard'
import InvoiceList from './InvoiceList'
import JobPdfList from './JobPdfList'
import JobVideoList from './JobVideoList'
import RoofInspectionList from './RoofInspectionList'
import AddMaterialsDialog from './AddMaterialsDialog'
import CompleteJobDialog from './CompleteJobDialog'
import ReturnJobDialog from './ReturnJobDialog'
import ReturnJobMwDialog from './ReturnJobMwDialog'
import SimpleInvoiceDialog from './SimpleInvoiceDialog'
import SimpleVideoDialog from './SimpleVideoDialog'
import SimplePdfDialog from './SimplePdfDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RoofContext } from "../../AppContext";

const listStyles = makeStyles(theme => ({
  root: {
    minWidth: 250,
    width: '99%',
    marginTop: '.7%',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },

  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`
  },

  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`
  },

  jobContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }
}))

const Job = props => {
  const list = listStyles()
  const { loading } = useAuth0()
  const { getTokenSilently, user } = useAuth0()
  const [redirect, setRedirect] = useState({
    set: false,
    to: { pathname: '/create-invoice', state: {} }
  })
  const [userPermissions, setUserPermissions] = useState([])
  const __ID =
    typeof props.location.state !== 'undefined'
      ? props.location.state.__ID
      : props.match.params.id

  const { roof, setRoof } = useContext(RoofContext);
  const [addMaterialsDialogOpen, setAddMaterialsDialogOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [returnDialogOpen, setReturnDialogOpen] = useState(false)
  const [returnMwDialogOpen, setReturnMwDialogOpen] = useState(false)
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false)
  const [invoiceDialog, setInvoiceDialog] = useState({})
  const [videoDialogOpen, setVideoDialogOpen] = useState(false)
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false)
  const [completingJob, setCompletingJob] = useState(false)
  const [returningJob, setReturningJob] = useState(false)
  const [returningJobType, setReturningJobType] = useState(0)
  const [jobDetails, setJobDetails] = useState(null)
  const [generatingInvoice, setGeneratingInvoice] = useState(false)
  const [oldMaterials, setOldMaterials] = useState([]);

  /* useEffect(() => {
    setRoof({
      materialList: [
        { name: '', price: '', qty: '', units: '' },
        { name: '', price: '', qty: '', units: '' },
        { name: '', price: '', qty: '', units: '' }
      ],
    });
  }, []) */

  const setMaterialList = (materialList) => {
    setRoof({ materialList })
  }

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(
          appPaths.apiPath + '/api/getUserPermissions',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        const responseData = await response.json()

        setUserPermissions(responseData)
      } catch (error) {
        console.error(error)
      }
    }

    if (!loading) {
      getPermissions()
      getOldMaterials()
    }
  }, [getTokenSilently, loading])

  const getOldMaterials = async () => {
    try {
      const token = await getTokenSilently()
      const response = await fetch(
        appPaths.apiPath + '/api/getJobMaterials',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ jobId: __ID })
        }
      )
      const responseData = await response.json()
      const materials = responseData.data.response.data.map(record => {
        const material = {
          recordId: record.recordId,
          name: record.fieldData.Name,
          price: record.fieldData.Price,
          qty: record.fieldData.Qty,
          units: record.fieldData.Units
        }

        return material
      })

      setOldMaterials(materials)
      setMaterialList(materials)
    } catch (error) {
      console.error(error)
    }
  }

  const completeJob = async () => {
    setCompletingJob(true)

    try {
      const token = await getTokenSilently()
      const response = await fetch(appPaths.apiPath + '/api/completeJob', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: JSON.stringify({ jobId: __ID, username: user.name, datetime: new Date(Date.now()).toLocaleString().split(',')[0] })
      })

      const responseData = await response.json()

      setDialogOpen(false)

      if (responseData.message) {
        setRedirect({ set: true, to: { pathname: '/jobs-list' } })
      } else {
        console.log('Error')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const returnJob = async () => {
    console.log('Returning job type:' + returningJobType)
    setReturningJob(true)

    try {
      const token = await getTokenSilently()
      const response = await fetch(appPaths.apiPath + '/api/returnJob', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: JSON.stringify({ jobId: __ID, jobType: returningJobType })
      })

      const responseData = await response.json()

      setReturnDialogOpen(false)
      setReturnMwDialogOpen(false)

      if (responseData.message) {
        setRedirect({ set: true, to: { pathname: '/jobs-list' } })
      } else {
        console.log('Error')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const generateSimpleInvoice = () => {
    // Open dialog
    setInvoiceDialogOpen(true)
    if (invoiceDialogOpen) {
    }
  }

  const uploadRepairVideo = () => {
    console.log('function successful')
    // HPDS 2/7/2024
    // This should open a modal window with a prompt to upload a job video.
    setVideoDialogOpen(true)
    if (videoDialogOpen) {
    }
  }
  
  const uploadJobPdf = () => {
    console.log('function successful')
    // HPDS 9/9/2024
    // This should open a modal window with a prompt to upload a job pdf.
    setPdfDialogOpen(true)
    if (pdfDialogOpen) {
    }
  }

  const getJobMapButtonLabel = () => {
    let label = 'View Job on Map and Make Edits'
    if (jobDetails && jobDetails.flag_RoofInspection) {
      label = 'Click here to see work requirements and add photos'
    }

    return label
  }

  return (
    <div className={list.jobContainer}>
      {redirect.set && <Redirect push to={redirect.to} />}

      <div className={list.jobContainer}>
        <List className={list.root}>
          {/* Job Details */}
          <JobDetails
            admin={userPermissions.includes('type:admin')}
            __ID={__ID}
            setJobDetails={setJobDetails}
          />

          <ListItem>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                setAddMaterialsDialogOpen(true)
              }}
            >
              Add Materials
            </Button>
          </ListItem>

          {/* Divider */}
          <Divider component='li' />

          {/* View Job on Map Button */}
          <ListItem className={list.jobContainer}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setRedirect({
                  set: true,
                  to: {
                    pathname: '/job-map',
                    state: {
                      __ID: __ID,
                      flag_RoofInspection: jobDetails.flag_RoofInspection
                    }
                  }
                })
              }}
            >
              {getJobMapButtonLabel()}
            </Button>
          </ListItem>

          {/* Create Invoice Button */}
          {userPermissions.includes('type:admin') && (
            <ListItem className={list.jobContainer}>
              {jobDetails && !jobDetails.flag_RoofInspection && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setRedirect({
                      set: true,
                      to: { pathname: '/job-invoice', state: { __ID: __ID } }
                    })
                  }}
                >
                  Add Materials and Create Invoice
                </Button>
              )}
              {jobDetails && jobDetails.flag_RoofInspection && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    generateSimpleInvoice()
                  }}
                >
                  {!generatingInvoice && 'Generate Simple Invoice'}

                  {generatingInvoice && (
                    <CircularProgress
                      color='info'
                      size='24px'
                    ></CircularProgress>
                  )}
                </Button>
              )}
            </ListItem>
          )}

          {/* Upload Video Button */}
          <ListItem className={list.jobContainer}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                uploadRepairVideo()
              }}
            >
              Upload Video of Roof Repair
            </Button>
          </ListItem>
          
          {/* Upload PDF Button */}
          <ListItem className={list.jobContainer}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                uploadJobPdf()
              }}
            >
              Upload PDF
            </Button>
          </ListItem>

          {/* Complete Job Button */}
          <ListItem className={list.jobContainer}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setDialogOpen(true)
              }}
            >
              Job Completed, Send to Office for Invoicing
            </Button>
          </ListItem>

          {/* Return Job Button */}
          <ListItem className={list.jobContainer}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setReturningJobType(0)
                setReturnDialogOpen(true)
              }}
            >
              Set Job to Return
            </Button>
          </ListItem>
          
          {/* Return for Manufacture Job Button */}
          <ListItem className={list.jobContainer}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setReturningJobType(1)
                setReturnMwDialogOpen(true)
              }}
            >
              Set Job to Return for Manufacture Warranty
            </Button>
          </ListItem>

          {/* Open Invoices */}
          {userPermissions.includes('type:admin') && (
            <ListItem className={list.jobContainer}>
              <InvoiceList setRedirect={setRedirect} __ID={__ID} />
            </ListItem>
          )}
          
          {/* Open JobPdfs */}
          {
            <ListItem className={list.jobContainer}>
              <JobPdfList setRedirect={setRedirect} __ID={__ID} />
            </ListItem>
          }
          
          {/* Open Job Videos */}
          {
            <ListItem className={list.jobContainer}>
              <JobVideoList setRedirect={setRedirect} __ID={__ID} jobDetails={jobDetails} />
            </ListItem>
          }

          {/* Roof Inspection Reports (if Job was created from a Roof Inspection) */}
          {userPermissions.includes('type:admin') &&
            jobDetails &&
            jobDetails.flag_RoofInspection != '' && (
              <ListItem className={list.jobContainer}>
                <RoofInspectionList __ID={__ID} />
              </ListItem>
            )}

          {/* List of Techs assigned to Job */}
          { ( userPermissions.includes('type:admin') &&
            <ListItem className={list.jobContainer}>
              <JobTechs location={{ state: { __ID: __ID } }} />
            </ListItem>
          )}

          {/* Time tracker */}
          <ListItem className={list.jobContainer}>
            <Timecard jobId={__ID} jobDetails={jobDetails} />
          </ListItem>
        </List>

        <AddMaterialsDialog
          open={addMaterialsDialogOpen}
          setOpen={setAddMaterialsDialogOpen}
          getOldMaterials={getOldMaterials}
          materialList={roof.materialList}
          setMaterialList={setMaterialList}
          oldMaterials={oldMaterials}
          jobId={__ID}
        />

        <CompleteJobDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          completeJob={completeJob}
          completingJob={completingJob}
        />

        <ReturnJobDialog
          open={returnDialogOpen}
          setOpen={setReturnDialogOpen}
          returnJob={returnJob}
          completingJob={returningJob}
        />
        
        <ReturnJobMwDialog
          open={returnMwDialogOpen}
          setOpen={setReturnMwDialogOpen}
          returnJob={returnJob}
          completingJob={returningJob}
        />

        <SimpleInvoiceDialog
          dialogOpen={invoiceDialogOpen}
          setDialogOpen={setInvoiceDialogOpen}
          selectedJobId={__ID}
          description={jobDetails}
        />
        <SimpleVideoDialog
          dialogOpen={videoDialogOpen}
          setDialogOpen={setVideoDialogOpen}
          selectedJobId={__ID}
          description={jobDetails}
        />
        <SimplePdfDialog
          dialogOpen={pdfDialogOpen}
          setDialogOpen={setPdfDialogOpen}
          selectedJobId={__ID}
          description={jobDetails}
        />
      </div>
    </div>
  )
}
export default Job
