import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import theme from '../../themes/theme';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItem, Divider } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EmailIcon from '@material-ui/icons/Email';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import appPaths from '../../applicationPaths.json'

export default function JobVideoList(props) {
    const [contentLoading, setContentLoading] = useState(true);
    const [JobVideoList, setJobVideoList] = useState([]);
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user, loading } = useAuth0();
    const [editMode, setEditMode] = useState(false);

    const openVideo = (e, jobVideo) => {
        e.stopPropagation();
        window.open(jobVideo);

    }

    function sendVideoEmail(videoId) {
      console.log(props.jobDetails.JobSite_ContactEmail_lu)
      if (!props.jobDetails.ProjectManagerEmail_lu && !props.jobDetails.JobSite_ContactEmail_lu) {
        window.alert('A Project Manager email or Job Site contact email has not been defined for this client. Please contact a technician to add an email to file.')
      } else {
        let clientEmail = '';
        if (props.jobDetails.ProjectManagerEmail_lu) { clientEmail = props.jobDetails.ProjectManagerEmail_lu } else
        { clientEmail = props.jobDetails.JobSite_ContactEmail_lu }
        /*clientEmail = 'rennel.parino@highpowerdata.com'*/
        let subject = 'Job Video Email for ' + props.jobDetails.Number_JobCombined
        let body = 'Hello, \n\nAttached is a link to a job completion video.\nPlease reach out if there are any questions. Link to video: http://' + window.location.host + '/job-doc/' + videoId
        let mailtoLink = `mailto:${clientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink, '_blank');
      }
      
    }

    const getDecodedUrl = async (url) => {
      const path = appPaths.apiPath + '/api/create/pdf_url'
      const decodedUrl = decodeURIComponent(url)
      console.log("url: " + url)
      const request = {
          method: 'POST',
          headers: { 
              Accept: 'application/json'
          },
          body: JSON.stringify({pdf_path: decodedUrl})
      };
      const result = await fetch(path, request);
      let obj = await result.json();
      console.log("path: " + decodeURIComponent(obj.pdf_path))
      window.open(decodeURIComponent(obj.pdf_path), '_blank')
     
      // return "https://google.com"
  }

    useEffect(() => {
        const callApi = async () => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/getJobVideoList", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify({jobId: props.__ID})
              });
              const responseData = await response.json();
              if(responseData.message) {
                console.log(responseData.data);
                setJobVideoList(responseData.data);
                setContentLoading(false);
              }
              else {
                setJobVideoList([]);
                setContentLoading(false);
              }
            } catch (error) {
              console.error(error);
            }
        };

        callApi();
    }, [getTokenSilently, props.__ID]);

    const deleteJobVideo = async (VideoId) => {
      if( window.confirm('Are you sure you want to delete this Job Video?') ) {
        setJobVideoList(prevState => {
          return prevState.filter(item => {
              return item.fieldData.__ID !== VideoId;
          });
        });
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/deleteJobVideo", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({__ID: VideoId})
          });
          const responseData = await response.json();
        } catch (error) {
          console.error(error);
        }
      }
    };

    const getSecondaryText = (jobVideo) => { 
      var filename = jobVideo.fieldData.FileName_ae;
      var datetime = jobVideo.fieldData.z_Modification_TS;
      return (
        <>
        {filename}
        <br/>
        {datetime}
        </>
      );
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
            {!contentLoading &&
            <>
                <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column'}}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Related Job Videos ({JobVideoList.length})</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <List style={{width:'100%'}}>
                                <Divider />
                                {JobVideoList.map(jobVideo => (
                                    <div key={jobVideo.fieldData.__ID}>
                                        <ListItem button disableRipple>
                                            <ListItemText button onClick={e => {getDecodedUrl(jobVideo.fieldData.S3Location.replace('https://duraroof-app.s3.amazonaws.com/', ''))}} primary={jobVideo.fieldData.Number_INV } secondary={getSecondaryText(jobVideo)}/>
                                            
                                            {editMode && <ListItemIcon>
                                                <IconButton label="Save" onClick={e => { e.stopPropagation(); setEditMode(false); }}>
                                                    <DoneIcon />
                                                </IconButton>
                                            </ListItemIcon>}
                                            {jobVideo.fieldData.S3Location && <ListItemIcon>
                                                <IconButton onClick={e => {sendVideoEmail(jobVideo.fieldData.__ID)}}>
                                                    <EmailIcon />
                                                </IconButton>
                                            </ListItemIcon>}
                                            {jobVideo.fieldData.S3Location && <ListItemIcon>
                                                <IconButton onClick={e => {getDecodedUrl(jobVideo.fieldData.S3Location.replace('https://duraroof-app.s3.amazonaws.com/', ''))}}>
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </ListItemIcon>}
                                            {<ListItemIcon>
                                                <IconButton onClick={e => deleteJobVideo(jobVideo.fieldData.__ID)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemIcon>}


                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                </Card>
            </>
            }
            {contentLoading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}

        </div>
    );

}